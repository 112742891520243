import React from "react"
import PropTypes from "prop-types"
import PaginationDot from "../PaginationDot"
import styles from "./styles.module.less"

// const style = {
//   root: {
//     position: "relative",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "left",
//     margin: "0 0 50px 0",
//   },
// }

class Pagination extends React.Component {
  handleSetIndex = (event, index) => {
    console.log(event, index)
    this.props.onChangeIndex(index)
  }

  handleNext = event => {
    let next = this.props.index + 1
    this.props.onChangeIndex(next)
  }

  handlePrevious = event => {
    let previous = this.props.index - 1
    this.props.onChangeIndex(previous)
  }

  render() {
    const { index, dots } = this.props

    const children = []

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={this.handleSetIndex}
        ></PaginationDot>
      )
    }

    return (
      <div className={styles.root}>
        <button
          onClick={this.handlePrevious}
          className={styles.previousButton}
          // style={{
          //   height: "50px",
          //   width: "50px",
          //   backgroundColor: "transparent",
          //   border: "0",
          //   color: "blue",
          //   fontSize: "3vw",
          //   fontWeight: "100",
          //   paddingRight: "20vw",
          //   left: "0",
          // }}
        >
          &#60;
        </button>
        {children}
        <button
          onClick={this.handleNext}
          className={styles.nextButton}
          // style={{
          //   height: "50px",
          //   width: "50px",
          //   backgroundColor: "transparent",
          //   border: "0",
          //   color: "blue",
          //   fontSize: "3vw",
          //   paddingLeft: "60vw",
          // }}
        >
          &#62;
        </button>
      </div>
    )
  }
}

Pagination.propTypes = {
  dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
}

export default Pagination
