import React from "react"
import Layout from "components/Layout"
import SEO from "components/seo"
import Whm from "components/ERGLandingPages/WomensHistoryMonth"

const WHM = () => (
  <Layout backgroundColor="#d9cdb8" navColor="white">
    <SEO
      title=" Thesis | Celebrating Women's History Month"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description=""
    />

    <Whm />
  </Layout>
)

export default WHM
