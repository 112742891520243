import React from "react"
import Reveal from "react-reveal/Reveal"

import Bottom from "./components/Bottom"
import Slides from "./components/Slides"
import BusinessCards from "./components/BusinessCards"
import ExpandCollapse from "./components/collapsedExpand"

import styles from "./styles.module.less"

const WomensHistoryMonth = () => {
  let businesses = [
    {
      name: "Wildfrau Studio",
      biztype: "Artist",
      webLink: "https://www.etsy.com/shop/WildfrauStudio",
      linkTitle: "www.etsy.com/shop/WildfrauStudio",
    },
    {
      name: "Drink Mamey",
      biztype: "Food/Drink",
      webLink: "https://www.drinkyajuice.com",
      linkTitle: "www.drinkyajuice.com",
    },
    {
      name: "Rose + Lincoln Juicery",
      biztype: "Food/Drink",
      webLink: "https://roseandlincoln.com",
      linkTitle: "roseandlincoln.com",
    },
    {
      name: "Seagrape Apothecary",
      biztype: "Beauty/Home Goods/ Classes",
      webLink: "https://seagrapeapothecary.com/",
      linkTitle: "seagrapeapothecary.com",
    },
    {
      name: "Psychic Sister",
      biztype: "Beauty/Home Goods/ Classes",
      webLink: "https://www.psychic-sister.com/",
      linkTitle: "www.psychic-sister.com",
    },
    {
      name: "Citizen Ruth",
      biztype: "Home Goods/Gifts",
      webLink: "https://citizenruth.com/",
      linkTitle: "citizenruth.com",
    },
    {
      name: "Treat",
      biztype: "Beauty Services",
      webLink: "http://www.treatyou.com",
      linkTitle: "www.treatyou.com",
    },
    {
      name: "Kee's Loaded Kitchen",
      biztype: "Food/Drink",
      webLink: "https://www.yelp.com/biz/kees-loaded-kitchen-portland",
      linkTitle: "www.yelp.com/biz/kees-loaded-kitchen-portland",
    },
    {
      name: "Here We Go Again",
      biztype: "Clothing",
      webLink: "https://hwga.com/",
      linkTitle: "hwga.com",
    },
    {
      name: "Naive Melody Vintage",
      biztype: "Clothing",
      webLink: "https://www.naivemelodyvintage.com/",
      linkTitle: "www.naivemelodyvintage.com",
    },
    {
      name: "11:11 Supply",
      biztype: "Home Goods",
      webLink: "https://www.1111supply.com/",
      linkTitle: "www.1111supply.com",
    },
    {
      name: "Ritual + Fancy",
      biztype: "Beauty",
      webLink: "https://www.ritualandfancy.com/",
      linkTitle: "www.ritualandfancy.com",
    },
    {
      name: "Staghorn Mercantile",
      biztype: "Plant Shop/Home Goods",
      webLink: "https://staghornmercantile.com/",
      linkTitle: "staghornmercantile.com",
    },

    {
      name: "Coy & Co",
      biztype: "Florist",
      webLink: "https://coycopdx.com/",
      linkTitle: "coycopdx.com",
    },
    {
      name: "Experimental Vintage",
      biztype: "Home Goods",
      webLink: "https://www.experimentalvintage.com/",
      linkTitle: "www.experimentalvintage.com",
    },
    {
      name: "Brand Zanele",
      biztype: "DEI Consultant",
      webLink: "https://www.brandzanele.com/",
      linkTitle: "www.brandzanele.com",
    },
    {
      name: "Hair Goals",
      biztype: "Hair/Beauty Services",
      webLink: "https://www.hairgoalsbyjd.com/",
      linkTitle: "www.hairgoalsbyjd.com",
    },
  ]
  let moreBusinesses = [
    {
      name: "Button Consignment",
      biztype: "Clothing",
      webLink: "https://www.buttonpdx.com/",
      linkTitle: "www.buttonpdx.com",
    },
    {
      name: "Monochromatic",
      biztype: "Clothing",
      webLink: "https://wearemonochromatic.com/",
      linkTitle: "wearemonochromatic.com",
    },
    {
      name: "Null Refillery",
      biztype: "Cleaning Products",
      webLink: "https://nullrefillery.wixsite.com/mysite",
      linkTitle: "nullrefillery.wixsite.com",
    },
    {
      name: "Orange and Blossom",
      biztype: "Food/Drink",
      webLink: "https://www.orangeblossomco.com/",
      linkTitle: "www.orangeblossomco.com",
    },
    {
      name: "Freeland Spirits",
      biztype: "Drink",
      webLink: "https://www.freelandspirits.com/",
      linkTitle: "www.freelandspirits.com",
    },
    {
      name: "Maven Collective",
      biztype: "Home Goods/Gifts",
      webLink: "https://www.mavencollective.com/",
      linkTitle: "www.mavencollective.com",
    },
    {
      name: "Jinju Patisserie",
      biztype: "Food",
      webLink: "https://jinjupatisserie.com/",
      linkTitle: "jinjupatisserie.com",
    },
    {
      name: "Equinox Tattoo",
      biztype: "Tattoo",
      webLink: "https://www.equinoxtattoo.com/",
      linkTitle: "www.equinoxtattoo.com/",
    },
    {
      name: "Face Envy",
      biztype: "Beauty",
      webLink: "https://www.equinoxtattoo.com/",
      linkTitle: "www.equinoxtattoo.com",
    },
    {
      name: "Little Roots",
      biztype: "Plant Shop",
      webLink: "https://www.facebook.com/LittleRootsPDX/",
      linkTitle: "www.facebook.com/LittleRootsPDX",
    },
    {
      name: "MisMoh Beauty Co",
      biztype: "Beauty",
      webLink: "https://www.mismohbeautyco.com/",
      linkTitle: "www.mismohbeautyco.com",
    },
    {
      name: "Spider City Brewing",
      biztype: "Brewery",
      webLink: "https://spidercitybrewing.com/home",
      linkTitle: "spidercitybrewing.com",
    },
    {
      name: "Uncaged Beauty",
      biztype: "Beauty",
      webLink: "https://uncagedbeautyscheduling.as.me/schedule.php",
      linkTitle: "uncagedbeautyscheduling",
    },
    {
      name: "JM Joints",
      biztype: "Creative Producer/Set Designer",
      webLink: "https://www.jmjoints.com/",
      linkTitle: "www.jmjoints.com",
    },
    {
      name: "Hue Vegan Cafe",
      biztype: "Vegan Food Cart",
      webLink: "https://www.huevegancafe.com/",
      linkTitle: "www.huevegancafe.com",
    },
    {
      name: "Toast'd",
      biztype: "Vegan Food",
      webLink: "https://toastdpdx.com/order",
      linkTitle: "toastdpdx.com",
    },
    {
      name: "Mama Dut",
      biztype: "Vegan Vietnamese Food",
      webLink: "https://www.mamadutfoods.com/",
      linkTitle: "www.mamadutfoods.com",
    },
    {
      name: "Mamancy",
      biztype: "Tea/Chocolates",
      webLink: "https://mamancy.com/",
      linkTitle: "mamancy.com",
    },
  ]

  return (
    <article className={styles.WomensHistoryMonth}>
      <Reveal>
        <div className={styles.body}>
          <div className={styles.introContainer}>
            <h1 className={styles.intro}>
              The womxn of Thesis sharing their lived experiences and
              perspectives
            </h1>
          </div>
          <div className={styles.hero}>
            <h1 className={styles.heroTitle}>In Our Words.</h1>
          </div>
          <div
            style={{
              padding: "3.5vw 0 20px 0px",
            }}
          >
            <Slides />
          </div>
        </div>
      </Reveal>
      <div>
        <img
          className={styles.pageDivider}
          src={
            "https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/Scroll%20Group%201.png"
          }
        />
      </div>
      <div className={styles.cardsHeadline}>
        <h2 className={styles.headline}>
          Ways to support <br />
          local womxn
        </h2>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          {businesses.map((business, key) => (
            <BusinessCards
              style={{ boxShadow: business.shadowColor }}
              backTitle={business.biztype}
              backLink={business.webLink}
              backLinkTitle={business.linkTitle}
              frontContent={business.name}
              key={key}
            />
          ))}
        </div>

        <div className={styles.grid}>
          <ExpandCollapse>
            {moreBusinesses.map((business, key) => (
              <BusinessCards
                style={{ boxShadow: business.shadowColor }}
                backTitle={business.biztype}
                backLink={business.webLink}
                backLinkTitle={business.linkTitle}
                frontContent={business.name}
                key={key}
              />
            ))}
          </ExpandCollapse>
        </div>
      </div>

      <Bottom />
    </article>
  )
}

export default WomensHistoryMonth
