import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.less"

const Eyebrow = props => <h3 className={styles.Eyebrow}>{props.children}</h3>

export default Eyebrow

Eyebrow.propTypes = {
  children: PropTypes.node.isRequired,
}
