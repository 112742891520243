import React, { Component } from "react"
import Eyebrow from "../Eyebrow"
import styles from "./styles.module.less"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import Pagination from "./Pagination"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const style = {
  root: {
    position: "absolute",
  },
  slide: {
    maxWidth: "810px",
    color: "#000",
  },
}

const totalSlides = 17
const maxIndex = totalSlides - 1

class Slides extends React.Component {
  state = {
    index: 0,
  }

  handleChangeIndex = i => {
    let index = i < 0 ? 0 : i >= maxIndex ? maxIndex : i
    this.setState({
      index,
    })
  }

  render() {
    const { index } = this.state

    return (
      <div className={styles.CarouselBorder}>
        <div className={styles.arrows}>
          <Pagination
            dots={0}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </div>
        <div style={styles.root}>
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
            enableMouseEvents
            interval={11000}
          >
            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a survivor never a victim; <br />
                self taught in the art of compassion for those forgotten <br />
                I am stupidly optimistic <br />
                I am strong of body and mind <br />
                I am a spontaneous 8+ hour drive with the love of my life <br />
                I am stubborn or determined depending on who you ask <br />
                I am unreservedly passionate
                <br />
                I am Motown classic on a 100-degree day <br />I am a
                sunrise/sunset off the side of the highway
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a woman <br />
                I am a feminist <br />
                I am sensitive <br />
                I am a sister <br />
                I am battling anxiety <br />
                I am missing life before COVID-19
                <br />
                I am lonely <br />
                I am thankful for family and friends <br />
                I am taking it each day at a time <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am an athlete <br />
                I am dance parties in the living room <br />
                I am hikes on the weekend <br />
                I am falling asleep to murder podcasts <br />
                I am a plant collector <br />
                I am bilingual <br />
                I am unable to function without coffee in the morning <br />
                I am a Midwesterner <br />
                I am that friend who will FaceTime you just to chat even if we
                haven’t talked in awhile <br />I am always rewatching{" "}
                <i>Grey’s Anatomy</i>
                <br />
                I am trying my best <br />
                I am working on believing in myself <br />
                I am afraid of making mistakes <br />
                I am learning to speak up <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a presence and proud of it
                <br />
                I am a daughter, sister, cousin, fiancé and pug mama <br />
                I am a safe space with unlimited room in my heart <br />
                I am often disappointed that I can’t save the world and all the
                lonely dogs <br />
                I am Ella Fitzgerald, Billie Holiday, Etta James, Otis Redding,
                Al Green, Aretha Franklin full volume on a record player,
                windows open, candles lit on a rainy Sunday morning
                <br />
                I am a believer in tummy butterflies, signs, gut feelings,
                karma, and that everything happens for a reason <br />I am a
                giver of love and care, no matter who you are
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am the stranger that will say hi to you and ask you how your
                day is going
                <br />I am terrified of death, not so much myself but my loved
                ones <br />
                I am from the bay and will forever stay hyphy <br />
                I am someone who will stand up to bullies and here if you need
                back up <br />
                I am hurting for old people during this pandemic and always
                really <br />
                I am someone who will feed you if you come over, even if you
                already ate <br />
                I am a handmade ceramic cup full of warm tea with extra lemon
                squeezies <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am an artist that strives to teach people how to find their
                sexy and natural beauty <br />
                I am a chef and my papa taught me everything I know <br />
                I am frustrated at inequality, racism, and greed <br />I am
                thankful for second chances because everyone deserves them{" "}
                <br />I am ora king salmon belly sashimi at the chef’s counter{" "}
                <br />
                I am family oriented and can’t wait to have my own kids to spoil
                rotten <br />
                I am sugar, spice, and everything nice <br />
                I am not judging you, we’re all human <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am pretty funny, considering <br />
                I am very well balanced
                <br />
                I am quite happy
                <br />
                I am fulfilled in all the ways that matter to me <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a being
                <br />
                alive on this Earth
                <br />
                I have seen death
                <br />
                and twice given birth
                <br />
                I am afraid
                <br />
                that might will make right
                <br />
                that strength of compassion
                <br />
                will fade out of sight
                <br />
                we all tell our tales
                <br />
                to connect and be heard
                <br />
                I am no different
                <br />
                on the stage of the word
                <br />
                but if poetry’s silver
                <br />
                listening’s gold
                <br />
                and platinum caring
                <br />
                about what you were told
                <br />
                I am humanity
                <br />
                I am alone
                <br />
                this is my family
                <br />
                all I have known
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a caring friend <br />
                I am a harry potter fan <br />
                I am an organizing freak <br />
                I am a romance book lover <br />
                I am a people person <br />
                I am a world traveler <br />
                I am a ramen enthusiast <br />
                I am a part-time student <br />
                I am strong <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a mother
                <br />
                I am tired
                <br />
                I am starting to feel hopeful again
                <br />
                I am giving it my all, even when my cup is empty
                <br />
                I am strong
                <br />
                I am learning it feels so much better to be vulnerable and ask
                for help
                <br />
                I am learning crying is necessary
                <br />
                I am trying to rest even if there is a lot to do
                <br />
                I am missing my work friends. A lot
                <br />
                I am missing my friend friends. A lot
                <br />
                I am especially missing my family. The most
                <br />
                I am counting down the days I can see a live show
                <br />
                I am so proud of how my child has grown over the last year
                <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am pushing for change where I can
                <br />
                I am always going to advocate for those I care about and work
                with
                <br />
                I am incredibly proud of my coworkers and how much they've
                pushed and carried their accounts during a horrible year
                <br />
                I am hoping they know how proud I am
                <br />
                I am letting go of stereotypes and listening to my body. No
                matter what the scale says
                <br />
                I am striving to be a leader in my department
                <br />
                I am hopeful I'll get there in a year
                <br />
                I am here and I hope that's enough
                <br />
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a woman who craves silence and solitude
                <br /> I am woman who, each day, rises well before the sun,
                shuffles across cold floorboards to the kitchen, and prepares a
                single cup of coffee. In the silence and darkness of my home, I
                wander to the deepest parts of my mind. The parts where I
                question if I want to be a mother, if I am a good enough friend,
                sister, daughter
                <br /> I am a woman whose body and soul aches for wide, open,
                and uncrowded natural spaces; the Appalachian Mountains, the
                dunes of Cape Cod, the still waters of Saranac Lake. With a dog
                at my side, I can walk miles alone, compartmentalizing my
                worries, desires, daydreams, and goals
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a woman who, during the darkest and most confusing times,
                will still choose to find joy
                <br />I am a woman who is learning to love herself so
                fearlessly, especially on the days when I feel like a ghost in
                my own body.
                <br />I am a woman who wants to be kind, even when faced with
                the brutal unkindness of the world
                <br /> I am a woman who will forever be married to amazement,
                because simply being a woman is amazing
                <br />I am a woman. Tenacious and steady. Curious and intuitive.
                Raw, gentle, uninhibited
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am ambitious but anxious <br />
                I am exhausted but I persevere
                <br />
                I am feminine AND strong
                <br />I am loving and caring
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a mom <br />
                I am tired
                <br />I am hanging on by a thread
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am unique <br />
                I am strong
                <br />
                I am intelligent
                <br />
                I am a leader
                <br />
                I am happy
                <br />
                I am blessed
                <br />
                I am present
                <br />
                I am womxn
                <br />
                I am witty
                <br />
                I am bright
                <br />
                I am direct
                <br />
                I am honest
                <br />
                I am sincere <br />
                I am trustworthy <br />
                I am honest
                <br />I am dependable
              </p>
            </div>

            <div style={Object.assign({}, style.slide)}>
              <p>
                I am a daughter, a sister, and an aunt
                <br />I am a Leo—fierce and full of love
                <br />I am endlessly curious
                <br />I am a glass half full
                <br />I am a forever student
                <br />I am right brain-driven
                <br />I am progress and growth
                <br />I am always striving to make the people around me happy
                <br />I am constantly reminding myself to put my happiness first
                <br />I am golden hour at the beach
                <br />I am the stranger who smiles at you while walking down the
                street
                <br />I am sunshine on a rainy day
                <br />I am keen on celebrating moments of joy
                <br />I am let’s drink one too many bottles of wine, get lost in
                conversation, and forget about time
                <br />I am not taking life too seriously
                <br />I am eternally grateful
              </p>
            </div>
          </AutoPlaySwipeableViews>
        </div>
      </div>
    )
  }
}

export default Slides
