import React, { useState } from "react"
import "./style.css"

const CollapseExpand = ({ collapsed, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  return (
    <div>
      <div
        className={`collapse-content ${isCollapsed ? "expanded" : "collapsed"}`}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
      <button
        className="collapse-button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? "See Less" : "See More"}
      </button>
    </div>
  )
}

export default CollapseExpand
