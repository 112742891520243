import React from "react"
import PropTypes from "prop-types"

const styles = {
  root: {
    maxWidth: "10%",
    width: "2.5%",
    cursor: "pointer",
    border: 0,
    background: "none",
    padding: 0,
  },
  dot: {
    backgroundColor: "white",
    height: "1vw",
    width: "1vw",
    borderRadius: "50%",
    border: "0.25px solid gray",
    margin: 3,
  },

  active: {
    backgroundColor: "#6B68E5",
  },
}

class PaginationDot extends React.Component {
  handleClick = event => {
    this.props.onClick(event, this.props.index)
  }

  render() {
    const { active } = this.props

    let styleDot

    if (active) {
      styleDot = Object.assign({}, styles.dot, styles.active)
    } else {
      styleDot = styles.dot
    }

    return (
      <button type="button" style={styles.root} onClick={this.handleClick}>
        <div style={styleDot} />
      </button>
    )
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PaginationDot
