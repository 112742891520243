import React from "react"
import styles from "./styles.module.less"

// const cardContainer = document.querySelector(".react-card")

// React component for the front side of the card
const CardFront = ({ children }) => {
  return (
    <div className={styles.sideFront}>
      <div className={styles.containerFluid}>{children}</div>
    </div>
  )
}

// React component for the back side of the card
const CardBack = ({ children }) => {
  return (
    <div className={styles.sideBack}>
      <div className={styles.containerFluid}>{children}</div>
    </div>
  )
}

// React component for the card (main component)
const BusinessCard = ({
  backTitle,
  backInfo,
  backLink,
  backLinkTitle,
  frontContent,
  frontCTA,
}) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardBody}>
        <CardBack>
          <div>{backTitle}</div>

          <a href={backLink} target="blank">
            Visit shop online.
          </a>

          {/* <a href="#" {...backLink}>
            {backLinkTitle}
          </a> */}
        </CardBack>

        <CardFront>
          <div>{frontContent}</div>
          <div className={styles.cta}>Tap for more info</div>
        </CardFront>
      </div>
    </div>
  )
}

export default BusinessCard
